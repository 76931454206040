import React from "react";

import { motion } from "framer-motion";

import Start from "../../../components/project/start";

import figma from "../../../../images/icons/figma.svg";
import zeplin from "../../../../images/icons/zeplin.svg";
import userLeft from "../../../../images/user-left.jpg";
import userRight from "../../../../images/user-right.jpg";

export function ProjectStart() {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const userLeftMotion = {
        initial: { opacity: 0, translateY: 0 },
        enter: {
            opacity: 1,
            translateY: -80,
            transition: { delay: 1, ...transition },
        },
    };

    const spanLeftMotion = {
        initial: { opacity: 0, translateX: -150, translateY: -50, rotate: -30 },
        enter: {
            opacity: 1,
            translateX: -50,
            translateY: 50,
            rotate: -10,
            transition: { delay: 1.2, ...transition },
        },
    };

    const userRightMotion = {
        initial: { opacity: 0, translateY: 160 },
        enter: {
            opacity: 1,
            translateY: 80,
            transition: { delay: 1.6, ...transition },
        },
    };

    const spanRightMotion = {
        initial: { opacity: 0, translateX: 150, translateY: -50, rotate: 30 },
        enter: {
            opacity: 1,
            translateX: 50,
            translateY: 50,
            rotate: 10,
            transition: { delay: 1.8, ...transition },
        },
    };

    const tools = [figma, zeplin];

    return (
        <Start
            title="Building platform's Design System"
            text={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum ac ligula at accumsan. Vivamus nunc felis, 
                scelerisque in dui ac, porttitor sagittis tortor. Pellentesque a pretium diam. Quisque facilisis hendrerit rutrum. 
                Phasellus vel velit tellus maecenas posuere.`}
            tools={tools}
            children={
                <motion.div
                    whileInView="enter"
                    viewport={{ once: true }}
                    transition={{ staggeredChildren: 1 }}
                    className="ekonsilio__chat"
                >
                    <motion.div
                        variants={userLeftMotion}
                        className="ekonsilio__chat--user left"
                        style={{ backgroundImage: `url(${userLeft})` }}
                    >
                        <motion.span variants={spanLeftMotion}>
                            👋 Hello !
                        </motion.span>
                    </motion.div>
                    <motion.div
                        variants={userRightMotion}
                        className="ekonsilio__chat--user right"
                        style={{ backgroundImage: `url(${userRight})` }}
                    >
                        <motion.span variants={spanRightMotion}>
                            Une question ?
                        </motion.span>
                    </motion.div>
                </motion.div>
            }
        />
    );
}

export default ProjectStart;
