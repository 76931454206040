import React from "react";

import { motion } from "framer-motion";

export function ProjectHeader({icon, company, dateStart, dateEnd}) {
    const headerMotion = {
        initial: { translateY: 120, opacity: 0 },
        animate: {
            translateY: 0,
            opacity: 1,
            transition: { duration: 0.5, delay: 0.9, ease: "easeInOut" },
        },
    };
    return (
        <motion.section
            variants={headerMotion}
            initial="initial"
            animate="animate"
            className="project__header"
        >
            <div className="container">
                <div className="project__header--company">
                    <div>
                        <img src={icon} alt="Company icon" />
                    </div>
                    <h3>{company}</h3>
                </div>
                <div className="project__header--date">
                    <p>{dateStart}</p>
                    <motion.svg width="42" height="14" fill="none">
                        <motion.path
                            d="M1 7H41"
                            stroke="#ffffff"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M35 1L41 7L35 13"
                            stroke="#ffffff"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </motion.svg>
                    <p>{dateEnd}</p>
                </div>
            </div>
        </motion.section>
    );
}

export default ProjectHeader;
