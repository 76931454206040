import React from "react";

import { useLocation, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "../pages/Home";
import Ekonsilio from "../pages/Ekonsilio";
import Bfast from "../pages/Bfast";

export function AnimatedRoutes({ handleScroll, document }) {
    const location = useLocation();

    function scrollTop() {
        document.lastElementChild.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }

    return (
        <AnimatePresence exitBeforeEnter onExitComplete={scrollTop}>
            <Routes
                location={location}
                key={location.pathname}
                onUpdate={() => {
                    console.log(location.pathname);
                }}
            >
                <Route path="/" element={<Home />} />
                <Route
                    path="ekonsilio"
                    element={<Ekonsilio handleScroll={handleScroll} />}
                />
                <Route
                    path="bfast"
                    element={<Bfast handleScroll={handleScroll} />}
                />
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;
