import React from "react";

import { motion } from "framer-motion";

import Hero from "./components/hero";
import Projects from "./components/projects";
import Experiences from "./components/experiences";

export function Home() {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const pageMotion = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition },
        exit: {
            translateY: 100,
            opacity: 0,
            transition: { duration: 1.5, ...transition },
        },
    };

    return (
        <motion.article
            variants={pageMotion}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <Hero />
            <Projects />
            {/* <Experiences /> */}
        </motion.article>
    );
}

export default Home;
