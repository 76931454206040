import React, { useState } from "react";

import { motion } from "framer-motion";

import Start from "./start";
import Palette from "./palette";
import IconsFont from "./icons-font";
import Components from "./components";
import Mid from "./mid";

export function Content() {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const containerMotion = {
        initial: { opacity: 0 },
        enter: {
            opacity: 1,
            transition: { delay: 0.3, ...transition },
        },
    };

    return (
        <section className="project__content">
            <motion.div
                variants={containerMotion}
                initial="initial"
                whileInView="enter"
                className="container"
            >
                <blockquote>
                    "The conversational experience at the service of customer relations"
                </blockquote>
            </motion.div>
            <Start />
            <Palette />
            <IconsFont />
            <Components />
            <Mid />
        </section>
    );
}

export default Content;
