import React from "react";

import { motion } from "framer-motion";

const gradients = [
    { name: "White", start: "#ffffff", stop: "#ffffff" },
    { name: "Black", start: "#191919", stop: "#191919" },
    { name: "Gray", start: "#3e5174", stop: "#f7f7f8" },
    { name: "Primary", start: "#0D6EFD", stop: "#CFE2FF" },
    { name: "Info", start: "#16B1FF", stop: "#D0EFFF" },
    { name: "Success", start: "#2BD85F", stop: "#D1FAE5" },
    { name: "Warning", start: "#FFCE0A", stop: "#FFF5CE" },
    { name: "Risk", start: "#FD7E14", stop: "#FFE5D0" },
    { name: "Danger", start: "#FF443A", stop: "#FFDAD8" },
];

export function Palette() {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const containerMotion = {
        initial: { opacity: 0 },
        enter: {
            opacity: 1,
            transition: { delay: 0.3, ...transition },
        },
    };

    const colorMotion = {
        initial: { opacity: 0, translateY: 200 },
        enter: {
            opacity: 1,
            translateY: 0,
            transition: transition,
        },
    };

    return (
        <div className="ekonsilio__palette">
            <motion.div
                variants={containerMotion}
                initial="initial"
                whileInView="enter"
                className="container"
            >
                <h2>Color palette</h2>
            </motion.div>
            <motion.div
                variants={containerMotion}
                initial="initial"
                whileInView="enter"
                className="container"
            >
                <motion.div
                    className="ekonsilio__palette--grid"
                    whileInView="enter"
                    viewport={{ once: true }}
                    initial="initial"
                    variants={{
                        enter: {
                            opacity: 1,
                            transition: {
                                delay: 0.3,
                                when: "beforeChildren",
                                staggerChildren: 0.1,
                                ...transition,
                            },
                        },
                    }}
                >
                    {gradients.map((gradient, index) => (
                        <motion.div
                            key={`${gradient.name} + ${index}`}
                            variants={colorMotion}
                            className="ekonsilio__palette--item"
                        >
                            <p>{gradient.name}</p>
                            <div
                                style={{
                                    background: `linear-gradient(to bottom, ${gradient.start}, ${gradient.stop})`,
                                }}
                            ></div>
                        </motion.div>
                    ))}
                </motion.div>
            </motion.div>
        </div>
    );
}

export default Palette;
