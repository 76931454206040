import React from "react";

import { Link } from "react-router-dom";

import { motion } from "framer-motion";

import ekonsilio from "../../../../images/companies/ekonsilio.svg";
import bfast from "../../../../images/companies/bfast.png";
import cfa from "../../../../images/companies/cfa.svg";
import icon from "../../../../images/icons/apple.svg";
import separator from "../../../../images/separator.svg";

const projects = [
    {
        id: "1",
        path: "/ekonsilio",
        icon: ekonsilio,
        date: "Nov 2019 - Today",
        title: "UI/UX Designer,",
        years: "Front-end Developer",
        award: "ekonsilio",
        location: "Le Mans, France",
    },
    {
        id: "2",
        icon: bfast,
        path: "/bfast",
        date: "May 2015 - Oct 2019",
        title: "UI/UX Designer,",
        years: "Web integrator",
        award: "Bfast-System",
        location: "Vibraye, France",
    },
    {
        id: "3",
        icon: cfa,
        path: "/",
        date: "Sept 2013 - Sept 2014",
        title: "Web Developer,",
        years: "Graphic Designer",
        award: "CFA des 3 villes",
        location: "Laval, France",
    },
    // {
    //     id: "4",
    //     icon: icon,
    //     path: "/",
    //     date: "03 July 2017",
    //     title: "Google Design Award",
    //     years: "2016-17",
    //     award: "Gold Winner",
    //     location: "United States",
    // },
];

export function Projects() {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const cardMotion = {
        initial: { opacity: 0, translateY: 20 },
        enter: { translateY: 0, opacity: 1, transition },
    };

    return (
        <section className="projects">
            <div className="container">
                <motion.div
                    initial="initial"
                    animate="enter"
                    variants={{
                        enter: { transition: { staggerChildren: 0.2 } },
                    }}
                    className="projects__grid"
                >
                    {projects.map((project, index) => (
                        <motion.div
                            variants={cardMotion}
                            key={`project-${index}`}
                        >
                            <Link to={project.path}>
                                <div className="projects__col">
                                    <motion.div
                                        whileHover={{ y: -10 }}
                                        transition={{duration: 0.1, ease: "easeInOut"}}
                                        className="card"
                                    >
                                        <div className="card__header">
                                            <div className="card__icon">
                                                <img
                                                    src={project.icon}
                                                    alt="icon"
                                                />
                                            </div>
                                            <p className="card__date">
                                                {project.date}
                                            </p>
                                        </div>
                                        <div className="card__body">
                                            <p className="card__award">
                                                {project.award}
                                            </p>
                                            <h3 className="card__title">
                                                {project.title}
                                                <br />
                                                {project.years}
                                            </h3>
                                            <img
                                                src={separator}
                                                alt="separator"
                                                className="separator"
                                            />
                                            <p className="card__location">
                                                {project.location}
                                            </p>
                                        </div>
                                    </motion.div>
                                </div>
                            </Link>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
}

export default Projects;
