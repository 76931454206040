import React, { useRef, useState } from "react";

import { useElementScroll } from "framer-motion";

import Header from "./components/Header";
import AnimatedRoutes from "./components/animated-routes";

export function App({ document }) {
    const ref = useRef();
    const { scrollY } = useElementScroll(ref);

    const [scroll, setScroll] = useState(true);
    const [handleScroll, setHandleScroll] = useState(0);

    scrollY.onChange(() => {
        setHandleScroll(scrollY.current / (window.innerHeight / 2));
        if (scrollY.current - scrollY.prev < 0 || scrollY.current < 120) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    });

    return (
        <>
            <Header scroll={scroll} />
            <main ref={ref}>
                <AnimatedRoutes
                    handleScroll={handleScroll}
                    document={document}
                />
            </main>
        </>
    );
}
