import React from "react";

import { motion } from "framer-motion";

export function Intro({ handleScroll, cover }) {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const maskMotion = {
        initial: { scale: 1.2, opacity: 0 },
        animate: {
            scale: 1,
            opacity: 1,
            transition,
        },
    };

    const cardMotion = {
        initial: { scale: 0.4, rotateX: 40, translateY: -200, opacity: 0 },
        animate: {
            scale: 0.9,
            rotateX: 16,
            translateY: -80,
            opacity: 1,
            transition: { duration: 0.5, delay: 0.4, ease: "easeInOut" },
        },
    };

    return (
        <>
            <motion.div
                variants={maskMotion}
                initial="initial"
                animate="animate"
                className="project__intro--mask"
            >
                <motion.div
                    animate={{
                        opacity: handleScroll < 1 ? handleScroll : 1,
                    }}
                ></motion.div>
            </motion.div>
            <motion.section
                animate={{
                    y: handleScroll > 0.5 ? -100 : 0,
                    opacity: handleScroll > 0.5 ? 0 : 1,
                }}
                transition={{ duration: 0.8, ...transition }}
                className="project__intro--cover"
            >
                <motion.div
                    variants={cardMotion}
                    initial="initial"
                    animate="animate"
                    className="card"
                >
                    <img src={cover} alt="Project cover" className="card__cover" />
                </motion.div>
            </motion.section>
        </>
    );
}

export default Intro;
