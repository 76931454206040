import React from "react";

import Illustration from "./illustration";

export function Hero() {
    return (
        <section className="hero">
            <div className="container">
                <h1 className="hero__title">
                    <span>Bringing</span>
                    <span>Digital Creativity</span>
                    <span>To Life</span>
                </h1>
            </div>
            <Illustration />
        </section>
    );
}

export default Hero;
