import React from "react";

import { motion } from "framer-motion";

import notifications from "../../../../images/components/notifications.svg";
import filters from "../../../../images/components/filters.svg";
import panel from "../../../../images/components/panel.svg";
import files from "../../../../images/components/files.svg";
import stats from "../../../../images/components/stats.svg";
import graph from "../../../../images/components/graph.svg";

export function Components() {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const containerMotion = {
        initial: { opacity: 0 },
        enter: {
            opacity: 1,
            transition: { delay: 0.3, ...transition },
        },
    };

    const notificationsMotion = {
        initial: { opacity: 0, translateX: -100 },
        enter: {
            opacity: 1,
            translateX: 0,
            transition: { delay: 0.5, ...transition },
        },
    };

    const filtersMotion = {
        initial: { opacity: 0, translateY: -100 },
        enter: {
            opacity: 1,
            translateY: 0,
            transition: { delay: 0.7, ...transition },
        },
    };

    const graphMotion = {
        initial: { opacity: 0 },
        enter: {
            opacity: 1,
            transition: { delay: 0.9, ...transition },
        },
    };

    const filesMotion = {
        initial: { opacity: 0, translateY: 100 },
        enter: {
            opacity: 1,
            translateY: 0,
            transition: { delay: 1.1, ...transition },
        },
    };

    const panelMotion = {
        initial: { opacity: 0, translateX: 100 },
        enter: {
            opacity: 1,
            translateX: 0,
            transition: { delay: 1.1, ...transition },
        },
    };

    const statsMotion = {
        initial: { opacity: 0, translateX: 50, translateY: 50 },
        enter: {
            opacity: 1,
            translateX: 0,
            translateY: 0,
            transition: { delay: 0.7, ...transition },
        },
    };

    return (
        <div className="ekonsilio__components">
            <motion.div
                variants={containerMotion}
                initial="initial"
                whileInView="enter"
                className="container"
            >
                <h2>Components</h2>
                <motion.div
                    initial="initial"
                    whileInView="enter"
                    viewport={{ once: true }}
                    className="ekonsilio__components--grid"
                >
                    <div>
                        <motion.img
                            variants={notificationsMotion}
                            src={notifications}
                            alt="notifications"
                        />
                    </div>
                    <div>
                        <motion.img
                            variants={filtersMotion}
                            src={filters}
                            alt="filters"
                        />
                        <div>
                            <div>
                                <motion.img
                                    variants={graphMotion}
                                    src={graph}
                                    alt="graph"
                                />
                                <motion.img
                                    variants={filesMotion}
                                    src={files}
                                    alt="files"
                                />
                            </div>
                            <div>
                                <motion.img
                                    variants={panelMotion}
                                    src={panel}
                                    alt="panel"
                                />
                                <motion.img
                                    variants={statsMotion}
                                    src={stats}
                                    alt="stats"
                                />
                            </div>
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
}

export default Components;
