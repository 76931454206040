import React from "react";

import { Link } from "react-router-dom";

import { motion, useAnimation } from "framer-motion";

import avatar from "../../images/avatar.jpg";

export function Header({ scroll }) {
    const controls = useAnimation();

    const variants = {
        initial: { opacity: 1, y: 0 },
        hidden: {
            opacity: 0,
            y: -100,
            transition: { duration: 0.4, ease: "easeOut" },
        },
        visible: {
            opacity: 1,
            y: 0,
            padding: "12px 0",
            transition: { duration: 0.2, ease: [0.43, 0.13, 0.23, 0.96] },
        },
    };

    if (scroll) {
        controls.start("visible");
    } else {
        controls.start("hidden");
    }

    return (
        <motion.header variants={variants} initial="initial" animate={controls} style={{ backdropFilter: scroll ? "blur(8px) brightness(120%) saturate(120%)" : "" }}>
            <div className="container">
                <nav>
                    <div className="profile">
                        <img
                            src={avatar}
                            alt="Profile picture"
                            className="profile__avatar"
                        />
                        <div className="profile__details">
                            <p>Maxime Luet</p>
                            <p>UI/UX designer + Front-end developer</p>
                        </div>
                    </div>
                    <div className="actions">
                        <Link to="/">
                            <span>Get in touch</span>
                        </Link>
                    </div>
                </nav>
            </div>
        </motion.header>
    );
}

export default Header;
