import React, { useState } from "react";

import { motion } from "framer-motion";

import icon from "../../../../images/icons/apple.svg";

const icons = [icon];

export function IconsFont() {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const containerMotion = {
        initial: { opacity: 0 },
        enter: {
            opacity: 1,
            transition: { delay: 0.3, ...transition },
        },
    };

    const fontMotion = {
        initial: { opacity: 0 },
        animate: { opacity: 0.72 },
        transition: transition,
    };

    const tabMotion = {
        default: {
            color: "#637590",
            backgroundColor: "#c5c7c9",
            transition: transition,
        },
        active: {
            color: "#212121",
            backgroundColor: "#ffffff",
            transition: transition,
        },
    };

    const [tabActive, setTabActive] = useState(300);

    return (
        <div className="ekonsilio__icons-font">
            <motion.div
                variants={containerMotion}
                initial="initial"
                whileInView="enter"
                className="container"
            >
                <div className="ekonsilio__icons">
                    <h2>Icons & Symbols</h2>
                    <div className="ekonsilio__icons--grid">
                        <div className="card">
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(8, 1fr)",
                                    gridTemplateRows: "repeat(5, 1fr)",
                                    gap: "24px",
                                }}
                            >
                                {icons.map((icon, index) => (
                                    <div
                                        key={`icon-${index}`}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src={icon}
                                            alt="icon"
                                            style={{ width: "30px" }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ekonsilio__font">
                    <h2>Typography</h2>
                    <div className="ekonsilio__font--content">
                        <div>
                            <h3>Averta</h3>
                        </div>
                        <motion.div
                            initial="initial"
                            animate="animate"
                            transition={{ delayChildren: 0.2 }}
                        >
                            <motion.p
                                variants={fontMotion}
                                style={{ fontWeight: tabActive }}
                            >
                                ABCDEFGHIJKLMNOPRSTUVWXYZ
                                <br />
                                abcdefghijklmnopqrstuvwxyz
                                <br />
                                01234567890
                            </motion.p>
                        </motion.div>
                        <div className="ekonsilio__font--tabs">
                            <div>
                                <motion.span
                                    variants={tabMotion}
                                    whileTap={() => {
                                        setTabActive(300);
                                    }}
                                    animate={
                                        tabActive == 300 ? "active" : "default"
                                    }
                                    className="ekonsilio__font--tabs-item"
                                >
                                    300
                                </motion.span>
                                <motion.span
                                    variants={tabMotion}
                                    whileTap={() => {
                                        setTabActive(400);
                                    }}
                                    animate={
                                        tabActive == 400 ? "active" : "default"
                                    }
                                    className="ekonsilio__font--tabs-item"
                                >
                                    400
                                </motion.span>
                                <motion.span
                                    variants={tabMotion}
                                    whileTap={() => {
                                        setTabActive(600);
                                    }}
                                    animate={
                                        tabActive == 600 ? "active" : "default"
                                    }
                                    className="ekonsilio__font--tabs-item"
                                >
                                    600
                                </motion.span>
                                <motion.span
                                    variants={tabMotion}
                                    whileTap={() => {
                                        setTabActive(700);
                                    }}
                                    animate={
                                        tabActive == 700 ? "active" : "default"
                                    }
                                    className="ekonsilio__font--tabs-item"
                                >
                                    700
                                </motion.span>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}

export default IconsFont;
