import React from "react";

import { motion } from "framer-motion";

export function Illustration() {
    const viewWidth = window.innerWidth;

    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const groundMotion = {
        initial: { y: 100, opacity: 0 },
        enter: { y: 0, opacity: 1, transition },
    };

    const cubes = {
        initial: { opacity: 0 },
        enter: { opacity: 1, transition: { staggerChildren: 0.1 } },
        animate: { opacity: 1, transition: { staggerChildren: 0.4 } },
    };

    const cubeXsMotion = {
        initial: {
            scale: viewWidth > 1440 ? 0.35 : 0.25,
            y: -100,
            opacity: 0,
        },
        enter: {
            scale: viewWidth > 1440 ? 0.35 : 0.25,
            y: 0,
            opacity: 1,
            transition,
        },
        animate: {
            scale: viewWidth > 1440 ? 0.35 : 0.25,
            translateY: [0, 10, -15, 5, 0],
            transition: {
                ease: "easeInOut",
                duration: 6,
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
            },
        },
    };

    const cubeSmMotion = {
        initial: { scale: viewWidth > 1440 ? 0.7 : 0.5, y: -150, opacity: 0 },
        enter: {
            scale: viewWidth > 1440 ? 0.7 : 0.5,
            y: 0,
            opacity: 1,
            transition,
        },
        animate: {
            scale: viewWidth > 1440 ? 0.7 : 0.5,
            translateY: [0, 9, -13.5, 4.5, 0],
            transition: {
                ease: "easeInOut",
                duration: 7,
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
            },
        },
    };

    const cubeMdMotion = {
        initial: { scale: viewWidth > 1440 ? 1 : 0.8, y: -125, opacity: 0 },
        enter: {
            scale: viewWidth > 1440 ? 1 : 0.8,
            y: 0,
            opacity: 1,
            transition,
        },
        animate: {
            scale: viewWidth > 1440 ? 1 : 0.8,
            translateY: [0, 8, -12, 4, 0],
            transition: {
                ease: "easeInOut",
                duration: 8,
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
            },
        },
    };

    const cubeXlMotion = {
        initial: { scale: viewWidth > 1440 ? 2 : 1.6, y: -75, opacity: 0 },
        enter: {
            scale: viewWidth > 1440 ? 2 : 1.6,
            y: 0,
            opacity: 1,
            transition,
        },
        animate: {
            scale: viewWidth > 1440 ? 2 : 1.6,
            translateY: [0, 4, -2, 1, 0],
            transition: {
                ease: "easeInOut",
                duration: 8,
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
            },
        },
    };

    return (
        <div className="illustration">
            <motion.div
                variants={groundMotion}
                initial="initial"
                animate="enter"
                className="ground"
            ></motion.div>
            <motion.div
                variants={cubes}
                initial="initial"
                animate={["enter", "animate"]}
            >
                <motion.div variants={cubeXsMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeMdMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeSmMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeXsMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeXlMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeSmMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeSmMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeMdMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeXsMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeMdMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
                <motion.div variants={cubeSmMotion} className="cube">
                    <div className="cube__face"></div>
                    <div className="cube__face--left"></div>
                    <div className="cube__face--right"></div>
                </motion.div>
            </motion.div>
        </div>
    );
}

export default Illustration;
