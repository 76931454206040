import React from "react";

import { motion } from "framer-motion";

export function Start({ title, text, tools, children }) {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const containerMotion = {
        initial: { opacity: 0 },
        enter: {
            opacity: 1,
            transition: { delay: 0.3, ...transition },
        },
    };

    return (
        <div className="project__start">
            <motion.div
                variants={containerMotion}
                initial="initial"
                whileInView="enter"
                className="container"
            >
                <div className="project__start--left">
                    <h2>{title}</h2>
                    <p>{text}</p>
                    <div>
                        {tools.map((tool, index) => (
                            <div
                                className="card"
                                key={`tool-${index}`}
                                style={{ padding: "16px" }}
                            >
                                <img
                                    src={tool}
                                    alt="Tools"
                                    style={{ width: "40px" }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {children}
            </motion.div>
        </div>
    );
}

export default Start;
