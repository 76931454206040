import React, { useState } from "react";

import { motion } from "framer-motion";

import Start from "./start";

export function Content() {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const containerMotion = {
        initial: { opacity: 0 },
        enter: {
            opacity: 1,
            transition: { delay: 0.3, ...transition },
        },
    };

    return (
        <section className="project__content">
            <motion.div
                variants={containerMotion}
                initial="initial"
                whileInView="enter"
                className="container"
            >
                <blockquote>
                    "A digital experience for everyone on any device"
                </blockquote>
            </motion.div>
            <Start />
        </section>
    );
}

export default Content;
