import React from "react";

import { motion } from "framer-motion";

import Intro from "../../components/project/intro";
import Header from "../../components/project/project-header";

import Content from "./components/content";

import ui from "../../../images/ui.jpg";
import bfast from "../../../images/companies/bfast.png";

export function Bfast({ handleScroll }) {
    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

    const pageMotion = {
        exit: {
            opacity: 0,
            transition: { duration: 1, ...transition },
        },
    };

    return (
        <motion.article variants={pageMotion} exit="exit" className="project">
            <Intro handleScroll={handleScroll} cover={ui} />
            <Header icon={bfast} company="Bfast-System" dateStart="May 2015" dateEnd="November 2019" />
            <Content />
        </motion.article>
    );
}

export default Bfast;
