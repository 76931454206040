import * as React from "react";

import { BrowserRouter } from "react-router-dom";

import { createRoot } from "react-dom/client";
import { App } from "./app/app";

const app = document.getElementById("app");
const root = createRoot(app);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App document={app} />
        </BrowserRouter>
    </React.StrictMode>
);
